<script setup>
  import loadingImage from '/public/images/lottie_files/finger-heart.json';
  import { useUsersStore } from '@/stores/users';
  import { useI18n } from 'vue-i18n'
  const { locale } = useI18n()

  async function hideLoading() {
    isLoading.value = true;
    setTimeout(() => {
      // nothing
    }, 1000); 
    try {
        preloader.value.classList.add('hidden');
      } catch (e) {
        // nothing
      }
    setTimeout(() => {
      isLoading.value = false;
    }, 500); 
  }
  
  const nuxtApp = useNuxtApp();
  const isLoading = ref(true);
  const preloader = ref(null);

  nuxtApp.hook("page:finish", async () => {
    hideLoading();
  });
  nuxtApp.hook("page:loading:end", async () => {
    hideLoading();
  });

  nuxtApp.hook("page:loading:start", async () => {
    isLoading.value = true;
    try {
      preloader.value.classList.remove('hidden');
    } catch (e) {
      // nothing
    }
  });

  onMounted(() => {
    const savedLanguage = useUsersStore().language
    if (savedLanguage) {
      locale.value = savedLanguage // Si existe un idioma guardado en localStorage, lo establece como idioma actual
    }
  })
</script>
<style lang="css">
.hidden {
  opacity: 0 !important;
  pointer-events: none;
}
</style>
<template>
  
    <NuxtLoadingIndicator color="primary" :height="4" />
    <div v-if="isLoading" ref="preloader" class="d-flex flex-column text-center h-screen align-center justify-center"
      style="opacity: 1; transition: opacity 0.5s ease; background-color: rgb(var(--v-theme-containerBg)) !important;"
    >
      <Vue3Lottie :animationData="loadingImage" width="100%" class="d-block" />
    </div>

    <NuxtLayout >
      <NuxtPage />
    </NuxtLayout>
  
</template>
