

import type { notificationType, profileType, languageType, appsLinkType, quickLinksType,searchType } from '@/types/HeaderTypes'
// 
// Notification
// 
import user1 from '/images/profile/user-1.jpg';
import user2 from '/images/profile/user-2.jpg';
import user3 from '/images/profile/user-3.jpg';
import user4 from '/images/profile/user-4.jpg';
import user5 from '/images/profile/user-5.jpg';

const notifications:notificationType[] = [
    {
        avatar: user1,
        title: 'Roman Joined the Team!',
        subtitle: 'Congratulate him'
    },
    {
        avatar: user2,
        title: 'New message received',
        subtitle: 'Salma sent you new message'
    },
    {
        avatar: user3,
        title: 'New Payment received',
        subtitle: 'Check your earnings'
    },
    {
        avatar: user4,
        title: 'Jolly completed tasks',
        subtitle: 'Assign her new tasks'
    },
    {
        avatar: user5,
        title: 'New Payment received',
        subtitle: 'Check your earnings'
    },
    {
        avatar: user1,
        title: 'Roman Joined the Team!',
        subtitle: 'Congratulate him'
    }
];

// 
// Profile 
// 
import icon1 from '/images/svgs/icon-account.svg';
import icon2 from '/images/svgs/icon-briefcase.svg';

const profileDD: profileType[] = [
    {
        avatar: icon1,
        title: 'My Profile',
        subtitle: 'Account settings',
        href: '/user/myProfile',
        showWarning: false //!useAuthStore().isThirdpartyFilled
    },
    {
        avatar: icon2,
        title: 'My Cards',
        subtitle: 'Manage payment methods',
        href: '/user/myCreditCards',
        showWarning: false // !useAuthStore().hasPaymentMethod
    },
];

// 
// Language
// 
import flag1 from '/images/flag/icon-flag-en.svg';
import flag5 from '/images/flag/icon-flag-co.svg';
const languageDD: languageType[] = [
    { title: 'Español', subtext: 'CO', value: 'es', avatar: flag5 },
    { title: 'English', subtext: 'USA', value: 'en', avatar: flag1 },
];

// 
// AppsLink
// 
// import img1 from '/images/svgs/icon-dd-chat.svg';
import img2 from '/images/svgs/icon-bars.svg';
// import img3 from '/images/svgs/icon-dd-invoice.svg';
// import img4 from '/images/svgs/icon-dd-date.svg';
// import img5 from '/images/svgs/icon-dd-mobile.svg';
// import img6 from '/images/svgs/icon-dd-lifebuoy.svg';
// import img7 from '/images/svgs/icon-dd-message-box.svg';
// import img8 from '/images/svgs/icon-dd-application.svg';
const appsLink: appsLinkType[] = [
    // {
    //     avatar: img1,
    //     title: 'Chat Application',
    //     subtext: 'New messages arrived',
    //     href: '/apps/chats'
    // },
    {
        avatar: img2,
        title: 'Reportes',
        subtext: 'Reportes Básicos',
        href: '/admin/reports'
    },
    // {
    //     avatar: img3,
    //     title: 'User Profile App',
    //     subtext: 'Get profile details',
    //     href: '/'
    // },
    // {
    //     avatar: img4,
    //     title: 'Calendar App',
    //     subtext: 'Get dates',
    //     href: '/'
    // },
    // {
    //     avatar: img5,
    //     title: 'Contact Application',
    //     subtext: '2 Unsaved Contacts',
    //     href: '/'
    // },
    // {
    //     avatar: img6,
    //     title: 'Account Setting App',
    //     subtext: 'Account settings',
    //     href: '/'
    // },
    // {
    //     avatar: img7,
    //     title: 'Email App',
    //     subtext: 'Get new emails',
    //     href: '/'
    // },
    // {
    //     avatar: img8,
    //     title: 'Notes Application',
    //     subtext: 'To-do and Daily tasks',
    //     href: '/'
    // }
];

// 
// Quick Links
// 
const quickLink: quickLinksType[] = [
    // {
    //     title: 'Pricing Page',
    //     href: '/theme-pages/pricing'
    // },
    {
        title: 'Login',
        href: '/auth/login'
    },
    {
        title: 'Register',
        href: '/auth/register'
    },
    // {
    //     title: '404 Error Page',
    //     href: '/auth/404'
    // },
    // {
    //     title: 'Notes App',
    //     href: '/apps/notes'
    // },
    // {
    //     title: 'User Application',
    //     href: '/apps/user/profile'
    // },
    // {
    //     title: 'Blog Design',
    //     href: '/apps/blog/posts'
    // },
    {
        title: 'Shopping Bag',
        href: '/apps/ecommerce/checkout'
    }
];

// 
// Search Data
// 
const searchSugg: searchType[] = [
    // {
    //     title: 'Modern',
    //     href: '/dashboards/modern'
    // },
    {
        title: 'eCommerce',
        href: '/dashboards/ecommerce'
    },
    // {
    //     title: 'Contacts',
    //     href: '/apps/contacts'
    // },
    {
        title: 'Shop',
        href: '/apps/ecommerce/products'
    },
    {
        title: 'Checkout',
        href: '/apps/ecommerce/checkout'
    },
    // {
    //     title: 'Chats',
    //     href: '/apps/chats'
    // },
    // {
    //     title: 'Notes',
    //     href: '/apps/notes'
    // },
    // {
    //     title: 'Pricing',
    //     href: '/theme-pages/pricing'
    // },
    // {
    //     title: 'Account Setting',
    //     href: '/theme-pages/account-settings'
    // },
];

export { notifications, profileDD, languageDD, appsLink, quickLink, searchSugg };

