<script setup>
import { useEcomStore } from '@/stores/apps/eCommerce';
const store = useEcomStore();
import iconSnapei from '/images/logos/snapei-solo.svg';
const props = defineProps({
  alert: {
    type: Object,
    default: () => ({ show: false, type: '', message: '', timer: 'primary', timeout: 6000 })
  }
});
watch(() => props.alert, (alert) => {
  if (alert.show){
    setTimeout(() => {
      alert.show = false;
    }, alert.timeout + 100);
  }
});

</script>
<template>
  <v-snackbar 
    v-model="alert.show"
    variant="elevated" 
    elevation="1" 
    color="white" 
    :timeout="alert.timeout ?? 1000" 
    close-on-back
    close-on-content-click
    vertical
    location="bottom center"
    class="my-snackbar"
    content-class="my-snackbar2"
    rounded="lg"
    multi-line
    timer="primary" 
    transition="dialog-bottom-transition" 
    v-bind="$attrs"  >
    <template v-if="alert.type === 'item-updated'">
      <slot name="content">
      </slot>
    </template>
    <template v-else-if="alert.type">
      <div  class="text-primary text-h5 py-0 my-0">
        {{ alert.type === 'success' ? $t('alerts.success') : $t('alerts.error') }}
      </div>
      <div class="d-flex align-center my-0 py-0">
        <v-img :src="iconSnapei" max-width="40" height="50" :aspect-ratio="1" cover /> 
        <div class="ml-2" v-html="alert.message"></div>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn :color="alert.type === 'success' ? 'primary' : alert.type" 
        variant="text" size="small" density="compact"
        @click="alert.show = false" class="pa-0 pr-4 my-0">
        {{ $t('basic.close') }} 
      </v-btn>
    </template>
  </v-snackbar>
</template>

<style lang="scss" >
.my-snackbar {
  transform: translateY(-24px) !important;
  .v-overlay__content {
    padding: 4px 6px !important;
  }
}
</style>