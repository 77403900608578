export default defineNuxtRouteMiddleware(async(to, from)  => {
	if (to.path === '/logout' || to.path === '/help/delete-account') {
		return true;
	}

	let tokenUserValidation = false;
	let tokenAppValidation = await useAuthStore().validate(false, "app");
	if (from.path != '/logout' && to.path != '/logout' && to.path !== '/auth/login') {
		tokenUserValidation = await useAuthStore().validate(false, "user");
	}
	if ((!tokenAppValidation || !tokenUserValidation) && !to.fullPath.startsWith('/auth')) {
		return navigateTo('/auth/login')
	} else if (useAuthStore().isLogged && to.fullPath === '/auth/login'){
		return navigateTo('/')
	} 
})
