import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { useEcomStore } from '@/stores/apps/eCommerce';
export const useStoresStore = defineStore({
    id: "stores",
    state: () => ({
        stores: {
			loading: false,
			data: []
		},
		selectedStore: {},
		parameters: {},
		restrictions: {},
		userCoordinates: {},
		maximumItems: 5,
		paymentGateway: {},
    }),
    actions: {
        async getStores() {
			this.stores.data = useAuthStore().userSession.nearby_stores;
		},
		async refreshNearbyStores(coordinates:object) {
			const baseUrl = useRuntimeConfig().public.apiBase;
			this.stores.loading = true;
			try {
				// console.log("antes de near_stores")
				if (coordinates && coordinates.lat && coordinates.lon){
					const data = await fetchWrapper.get(`${baseUrl}/api/v1/management/stores/near_stores/?lat=${coordinates.lat}&lon=${coordinates.lon}`);
					// console.log("data: ", data);
					this.stores.loading = false;
					return data.results ?? data;
				} else {
					this.stores.loading = false;
					return [];
				}
			} catch (error) {
				this.stores.loading = false;
				throw error;
			}
		},
		async setSelectedStore(store:object) {
			this.selectedStore = store;
			try {
				const companyColor = store.company_info.primary_color
				localStorage.setItem("primaryColor", companyColor)
			} catch (e){
				// console.error("Error setting theme color: ", e);
			}

			if (!store){
				this.parameters = {};
				this.restrictions = {};
				this.paymentGateway = {};
				this.maximumItems = 5;
				return;
			}
			
			this.maximumItems = store.qty_max;
			
			// Fetch the client configuration information:
			const baseUrl = useRuntimeConfig().public.apiBase;
			if (store && store.company){
				let extra_fields = { parameters: {}, restrictions: {} };
				if (store.company_info.extra_fields){
					extra_fields = store.company_info.extra_fields
					this.paymentGateway = store.payment_gateway;
				} else {
					let response = await fetchAppWrapper.get(`${baseUrl}/api/v1/management/companies/${store.company}/`);
					if (response && response.extra_fields){
						extra_fields = response.extra_fields;
						this.paymentGateway = response.payment_gateway;
					}
				}
				this.parameters = extra_fields.parameters;
				this.restrictions = extra_fields.restrictions;
				useEcomStore().setStartTime(new Date());
			}
		},
		setUserCoordinates(coordinates:object){
			this.userCoordinates = coordinates;
		},
		async setNearbyStores(stores:object){
			this.stores.data = stores;
		},
		cleanStores(){
			this.stores.data = [];
            this.selectedStore = {};
		}
    },
    getters: {
        getSelectedStore(state) {
			// console.log("pasa por getSelectedStore", state)
            return state.selectedStore;
        },
		async getNearbyStores(state) {
			//return state.stores.data.filter((store: { isNearby: boolean; }) => store.isNearby === true);
			return state.stores.data && state.stores.data.length > 0 ? state.stores.data.sort((a:any, b:any) => a.currentDistance - b.currentDistance) : state.stores.data ?? [];
		},
		getUserCoordinates(state) {
			// console.log("getUserCoordinates:", state.userCoordinates)
			//return state.stores.data.filter((store: { isNearby: boolean; }) => store.isNearby === true);
			return state.userCoordinates || {};
		},
		getPackagingOption(state) {
			if (state.selectedStore){
				let packageItem = state.selectedStore?.company_info?.extra_fields?.parameters?.packaging
				if (packageItem && packageItem.length > 0){
					// TODO: Por ahora es solo un ítem [0], pero pueden ser varios tipos de bolsas
					let alreadyAdded = useEcomStore().findProductOnCart(packageItem[0].ean);
					packageItem[0].added = alreadyAdded ? true : false;
					// packageItem[0].item = !alreadyAdded ? useEcomStore().fetchProduct(packageItem[0].ean, true) : alreadyAdded;
                    return packageItem[0]; // <-- { added: true/false }
                } else {
					return { added: null };
				}
			} else {
				return { added: null };
			}
		}
    },
	persist: {
        storage: persistedState.localStorage,
    },
});
