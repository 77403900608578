import { defineStore } from 'pinia';
import productImage from '/images/product_categories/OTROS.png';
// types
import type { InvoiceCreation, Invoice } from '@/types/apps/invoiceType';

export const useInvoiceStore = defineStore({
    id: 'invoice',
    state: () => ({
        invoiceId: '',
        paymentStatus: '',
        appliedPromotions: [],
        paymentInfo: {},
        totalInvoices: 0
    }),
    getters: {
        getPaymentStatusName(state){
            switch (state.paymentStatus) {
                case 'approved': return 'approved'; 
                case 'in_process': return 'pending'; 
                case 'rejected': return 'rejected'; 
                case null: 
                case '': return 'uninitialized';
            }
            return state.paymentStatus;
        },
        getPaymentInfo(state){
            return state.paymentInfo;
        }
    },
    actions: {
        // Fetch Customers from action
        async fetchInvoices(page?:string) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                const response = await fetchWrapper.get(
                    `${baseUrl}/api/v1/management/invoices/my_invoices/${page}`, 
                );
                if (response){
                    if (response.count){
                        this.totalInvoices = response.count;
                    }
                    return response
                }
            } catch (error) {
                // alert(error);
                return Promise.reject(error);
            }
        },
        async createInvoice(payload:InvoiceCreation) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                if (!payload.products || payload.products.length === 0){
                    return false;
                }
                const response = await fetchWrapper.post(
                    `${baseUrl}/api/v1/management/invoices/`, payload
                );
                if (response){
                    this.invoiceId = response.id.toString();
                    this.paymentStatus = 'created';
                    return response
                }
            } catch (error) {
                // alert(error);
                console.log(error);
                return Promise.reject(error);
            }
        },
        async updateInvoice(payload:object) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                const response = await fetchWrapper.patch(
                    `${baseUrl}/api/v1/management/invoices/${this.invoiceId}/`, payload
                );
                if (response){
                    if (response.message?.status === 3 || response?.status === 3){
                        this.paymentStatus = 'approved';
                        this.paymentInfo = response.payment_log;
                    } else if (payload.status && payload.status === 2){
                        this.paymentStatus = 'in_payment';
                        this.appliedPromotions = response.applied_promotions;
                    }
                    return response
                }
            } catch (error) {
                // alert(error);
                console.log(error);
                return Promise.reject(error);
            }
        },
        async getInvoice(id:string) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                const response = await fetchWrapper.get(
                    `${baseUrl}/api/v1/management/invoices/${id}/`);
                if (response){
                    if (response.message?.status === 3 || response?.status === 3){
                        this.paymentStatus = 'approved';
                        this.paymentInfo = response.payment_log;
                        this.invoiceId = response.id;
                    }
                    return response
                }
            } catch (error) {
                // alert(error);
                console.log(error);
                return Promise.reject(error);
            }
        },
        async submitFeedback(payload:object) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                const response = await fetchWrapper.post(
                    `${baseUrl}/api/v1/management/feedback/`, payload
                );
                if (response){
                    return response
                }
            } catch (error) {
                // alert(error);
                console.log(error);
                return Promise.reject(error);
            }
        },
        async setInvoice(invoice:string){
            this.invoiceId = invoice;
        },
        async setPaymentStatus(status:string){
            this.paymentStatus = status;
        },
        async setPaymentInfo(info:any){
            this.paymentInfo = info;
        },
        async setPromotions(promotions:any){
            this.appliedPromotions = promotions;
        }
    },
    persist: {
        storage: persistedState.localStorage,
    },
});
