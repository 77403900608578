import { defineStore } from 'pinia';

interface CreditCard {
    id?: string;
    color: string;
    isDefault: boolean;
    month: string;
    name: string;
    number: string;
    type: string;
    year: string;
    bin: string;
    alias?: string;
}

interface UserState {
    users: Record<string, any> | { loading: boolean };
    creditCards: CreditCard[];
    language: string;
}

export const useUsersStore = defineStore({
    id: 'users',
    state: (): UserState => ({
        users: {},
        creditCards: useAuthStore().userSession?.user?.thirdparty?.credit_card || [],
        language: (navigator.language || navigator.languages[0]).split('-')[0]
    }),
    actions: {
        async getUserForEmail(email: string) {
            this.users = { loading: true };
            const baseUrl = useRuntimeConfig().public.apiBase;
            // #TODO: Consultar el email por Body, no por URL
            return fetchAppWrapper.get(`${baseUrl}/base/users/?email=${email}`)
                .then((data)=> {return data })
                .catch(error => {
                    throw error;
                })
        },
        async getUserForUsername(username: string) {
            this.users = { loading: true };
            const baseUrl = useRuntimeConfig().public.apiBase;
            // #TODO: Consultar el username por Body, no por URL
            return fetchAppWrapper.get(`${baseUrl}/base/users/?username=${username}`)
                .then((data)=> {return data })
                .catch(error => {
                    throw error;
                })
        },
        async getUserCreditCards() {
            this.users = { loading: true };
            const baseUrl = useRuntimeConfig().public.apiBase;
            const thirdpartyId = useAuthStore().userSession?.user?.thirdparty?.id;
            if (!thirdpartyId) return [];
            return await fetchWrapper.get(`${baseUrl}/api/v1/management/thirdparties/${thirdpartyId}/my-cards/`)
                .then((data)=> {
                    if (data){
                        this.setCreditCards(data)
                        return data;
                        // const company_id = useStoresStore().selectedStore?.company_info?.id
                        // if (company_id){
                        //     const payment_gateway = data.find(x => x.company==company_id)?.payment_gateway;
                        //     this.setCreditCards(payment_gateway)
                        //     return payment_gateway 
                        // } else {
                        //     this.setCreditCards([])
                        // }
                    } else {
                        this.setCreditCards([])
                        return [];
                    }
                })
                .catch(error => {
                    this.setCreditCards([])
                    // console.error(error);
                    // throw error;
                    return [];
                })
        },
        async setCreditCards(data: CreditCard[]){
            this.creditCards = data;
        },
        async editCreditCard(data: CreditCard){
            const baseUrl = useRuntimeConfig().public.apiBase;
            const thirdpartyId = useAuthStore().userSession?.user?.thirdparty?.id;
            const cardId = data.id;
            return fetchWrapper.patch(`${baseUrl}/api/v1/management/thirdparties/${thirdpartyId}/my-cards/${cardId}/update/`, { metadata: data } )
                .then(response => {
                    this.getUserCreditCards();
                    return { success: true, credit_cards: this.creditCards };
                })
                .catch(error => {
                    console.error("error setting default credit-card", error)
                    return { error: true }
                })
        },
        async deleteCreditCard(cardId: string){
            const baseUrl = useRuntimeConfig().public.apiBase;
            const thirdpartyId = useAuthStore().userSession?.user?.thirdparty?.id;
            return fetchWrapper.delete(`${baseUrl}/api/v1/management/thirdparties/${thirdpartyId}/my-cards/${cardId}/`)
                .then(response => {
                    this.getUserCreditCards();
                    return { success: true, credit_cards: this.creditCards }
                })
                .catch(error => {
                    console.error("error deleting credit-card", error)
                    return { error: true }
                })
        },
        async setLanguage(lang: string){
            this.language = lang
        }
    },
    getters: {
        getCreditCards(state) {
            return state.creditCards;
        },
        hasPaymentMethod(state) {
            return state.creditCards.length > 0
        },
    },
    persist: {
        storage: persistedState.localStorage,
    },
});