<script setup lang="ts">
import { ref } from 'vue';
import { RouterLink } from 'vue-router';
const primary = ref('rgb(var(--v-theme-primary))');
const secondary = ref('rgb(var(--v-theme-secondary))');
import Logo from '/public/images/logos/snapei-logo.svg';
</script>
<template>
    <div class="logo">
        <NuxtLink to="/">
            <VImg :src="Logo" :height="`${$attrs.height ?? 60}`" :width="`${$attrs.width ?? 230}`" v-bind="$attrs" >
            </VImg>
        </NuxtLink>
    </div>
</template>

